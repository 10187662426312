import React from "react";
import ReactDOM from "react-dom";
import Maintenance from "./Maintenance";

function Root() {
  return (
    <Maintenance titleBar={false}/>
  );
}

ReactDOM.render(<Root/>, document.getElementById("portoberater"));