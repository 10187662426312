import React from "react";
import constructionSVG from "url:./maintenance_construction.svg";
import arrowLeft from "url:./maintenance_arrow_left_back.svg";

function Maintenance(props) {

  const headline = "Es finden aktuell Wartungsarbeiten statt";
  const text1 = "Wir entwickeln den Portoberater kontinuierlich für Sie weiter. Derzeit arbeiten wir daran, Ihnen noch mehr relevante Informationen zu den vorgeschlagenen Produkten anbieten zu können.";
  const text2 = "Ab 10 Uhr sind wir wieder für Sie da.";

  const backgroundStyling = {
    margin: "0",
    height: "100vh",
    overflowX: "hidden",
    background: "rgb(245, 245, 245)"
  };
  const imageStyling = {
    minHeight: "120px",
    maxHeight: "467px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "16px",
  };

  const titleBarStyling = {
    display: "flex",
    height: "54px",
    position: "sticky",
    top: "0",
    marginBottom: "16px",
    marginTop: "-16px",

    minWidth: "320px",

    background: "#ffffff",
    boxShadow: "0 0.5px rgba(0, 0, 0, 0.25)",
    zIndex: "1000"
  };

  const iconWrapperStyling = {
    display: "flex",
    padding: "0 16px",
    width: "44px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    border: "0"
  };

  const iconStyling = {
    height: "16px"
  };

  const titleStyling = {
    flex: "1 1 0",
    paddingTop: "18px",
    marginTop: '0px',
    textAlign: "center",
    color: "rgba(0,0,0,0.9)",
    marginRight: "56px", /*To compensate the back icon on the left*/
    fontSize: "20px",
    lineHeight: "20px"
  };

  const headlineStyling = {
    marginTop: "32px",
    textAlign: "center"
  };

  const infoTextStyling = {
    fontWeight: "normal",
    marginRight: "8px",
    marginLeft: "8px",
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontSize: "14px",
  };

  function onBackButtonClick() {
    window.location.assign("/de/privatkunden/pakete-versenden/verteilen.html");
  }

  return (
    <>
      <div>
        {props.titleBar && (
          <div style={titleBarStyling}>
            <div style={iconWrapperStyling}
                 onClick={() => onBackButtonClick()}>
              <img style={iconStyling} src={arrowLeft} alt={"arrowLeft"}/>
            </div>
            <h4 style={titleStyling}>Portoberater</h4>
          </div>
        )}
        <div id={"PORTOBERATER-scroll-container"} style={backgroundStyling}>
          <h4 style={headlineStyling}>{headline}</h4>
          <img style={imageStyling} src={constructionSVG} alt={"Construction"}/>
          <h6 style={infoTextStyling}>{text1}</h6>
          <h6 style={infoTextStyling}>{text2}</h6>
        </div>
      </div>
    </>
  );
}

export default Maintenance;

